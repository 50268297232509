@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/borders.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

zapp-breadcrumb {
  .p-breadcrumb-list {
    .p-menuitem-separator {
      margin: 0 0.5rem 0 0.5rem;
    }
    homeicon {
      padding: 0 0.5rem 0 0;
    }
    i.pi {
      padding: 0 0.5rem 0 0;
    }
    .breadcrumb-intermediate-item {
      cursor: pointer;
      user-select: none;
    }
  }
  .hide-home-icon homeicon {
    display: none;
  }
  .empty-breadcrumbs .p-menuitem-separator {
    display: none;
  }
}

.p-tieredmenu.p-tieredmenu-overlay {
  background-color: white;
  margin-top: 10px;
  border: 1px solid #d3dce8;
  &::before {
    content: " ";
    position: absolute;
    top: -10px; /* Adjust based on the actual position */
    left: 20px; /* Adjust to align with the desired location */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #d3dce8;
  }
  &::after {
    content: " ";
    position: absolute;
    top: -8px; /* Slightly lower than ::before for the effect */
    left: 22px; /* Center within the ::before element */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
  .menu-item-active-route {
    background: #EFF6FF;
    span {
      color: #1D4ED8;
    }
  }
}

.breadcrumbcontrol-status-mixin() {
  .p-breadcrumb.p-component {
    .background-color(@BackgroundColor);
    .text-decoration(@FontDecoration);
    font-family: @FontFamily;
    .font-size(@FontSize);
    .font-style(@FontStyle);
    .font-weight(@FontWeight);
    .height(@Height);
    .margin-bottom(@MarginBottom);
    .margin-left(@MarginLeft);
    .margin-right(@MarginRight);
    .margin-top(@MarginTop);
    .padding-bottom(@PaddingBottom);
    .padding-left(@PaddingLeft);
    .padding-right(@PaddingRight);
    .padding-top(@PaddingTop);
    box-shadow: @Shadow;
  }
  .p-breadcrumb {
    .border-left-color(@BorderLeftColor);
    .border-right-color(@BorderRightColor);
    .border-top-color(@BorderTopColor);
    .border-bottom-color(@BorderBottomColor);
  }
  .p-breadcrumb.p-component {
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  }
  .p-breadcrumb {
    .border-bottom-style(@BorderBottomStyle);
    .border-bottom-width(@BorderBottomWidth);
    .border-top-style(@BorderTopStyle);
    .border-top-width(@BorderTopWidth);
    .border-left-style(@BorderLeftStyle);
    .border-left-width(@BorderLeftWidth);
    .border-right-style(@BorderRightStyle);
    .border-right-width(@BorderRightWidth);
  }
  .p-breadcrumb li > a {
    .color(@ForegroundColor);
  }
  .p-breadcrumb li > chevronrighticon {
    .color(@ForegroundColor);
  }
}
