@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/borders.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

zapp-menu {
  justify-content: space-between;
  z-index: 10;

  p-menubar, .p-menubar {
    flex-grow: 1;

    .p-menubar-root-list, .p-submenu-list {
      z-index: 10;
    }
  }

  p-menubar.p-menu-left {
    float: right;
  }

  .menu-search-icon {
    cursor: pointer;
  }

  .toggle {
    margin-right: 0.5rem;
    cursor: pointer;
  }

  .p-menubar-end {
    p-autocomplete input {
      padding: 0.4rem;
      margin-right: 1rem;
      opacity: 0.8;
    }

    p-autocomplete input:focus {
      opacity: 1;
    }

    p-autocomplete timesicon > svg {
      right: 1.5rem !important;
    }
  }

  .most-used-menu-icon {
    margin-right: 1rem;
  }

  .most-used-menu-remove-icon {
    margin-left: 0.5rem;
  }

  zappicon.index-search-icon > i {
    padding: 0;
    margin-right: 1rem;
  }
}

/* Vertical */
zapp-menu {
  p-tieredmenu {
    display: block;
  }
  .search-icon-container {
    cursor: pointer;
  }
  .sidebar-container .menu-search-icon {
    margin-top: 0.25rem;
    text-align: center;
  }
  .sidebar-container .menu-search-icon i {
    width: 100%;
  }
  .menu-search-icon > i {
    padding: 0.75rem 1.25rem;
  }
  .sidebar-container {
    height: 100%;
  }
  .vertical.p-tieredmenu {
    height: 100%;
    border-radius: 0;
    .collapsed-toggle {
      padding-left: 0.5rem;
    }
  }
  .sidebar-container .p-tieredmenu {
    width: 100%;
  }
  .vertical.p-tieredmenu {
    border: none;
  }
  .collapsed-menu > .vertical.p-tieredmenu {
    min-width: 0;
    width: auto;
  }
  .vertical.p-tieredmenu .p-menuitem-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-autocomplete-clearable {
    margin-left: 1rem;
    margin-right: 1rem;
    display: block;
    .p-inputwrapper {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  .p-tieredmenu.bottom-menu .p-submenu-list {
    /* Fix bottom items from opening submenu to the top */
    /* Not sure how that works, copilot suggested it */
    top: auto !important;
    bottom: 0;
  }
  .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    margin-right: 1rem;
  }
  .collapsed-menu > .vertical.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    margin-right: 0;
  }
}

.menucontrol-status-mixin() {
  .sidebar-container {
    background-color: @navbar-bg-color;
  }
  .menu-search-icon > i {
    color: @navbar-color;
  }
  .menu-search-icon > i:hover {
    background-color: @navbar-item-hover-bg;
  }
  .search-icon-container:hover {
    background-color: @navbar-item-hover-bg;
  }
  .vertical.p-tieredmenu {
    .p-menuitem-link:hover .p-menuitem-text {
      color: @navbar-item-hover-color;
    }
    .p-menuitem-link:hover .p-menuitem-icon {
      color: @navbar-item-hover-color;
    }
    .p-menuitem-link .p-menuitem-icon {
      color: @navbar-color;
    }
    zappicon.toggle > i, zappicon.collapsed-toggle > i {
      color: @sidenav-hamburger-color;
    }
    .p-menuitem.header .p-menuitem-text {
      color: @navbar-brand-color;
      font-size: @navbar-brand-font-size;
      font-weight: @navbar-brand-font-weight;
    }
    .p-menuitem.header a {
      background-color: @navbar-brand-bg-color;
    }
    .p-menuitem.header:hover a {
      background-color: @navbar-brand-bg-color-hover;
    }
  }
}

:host ::ng-deep {
  p-menubarsub {
    width: 100%;
  }

  .p-menubar > a.p-menubar-button {
    width: 3rem;
    height: 3rem
  }
}

.p-menubar {
  .pull-right {
    margin-left: auto;
  }
  .pull-right:not(.p-hidden) ~ .pull-right {
    margin-left: unset;
  }
  .pull-right .p-submenu-list {
    left: -6rem !important;

    .p-submenu-list {
      left: -12.5rem !important;
    }
  }
}

.image-menu-item {
  & > a, & > div > a {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    & > span.p-menuitem-icon {
      width: 50px;
      height: 50px;

      &:before {
        content: '';
      }
    }
  }
}
