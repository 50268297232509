
@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

:host ::ng-deep {

  .content-message {
    opacity: 0.5;
    text-align: center;
  }
}
.fileattachment-status-mixin() {
  .file-attachment-container {
    .background-color(@input-bg);
    .color(@ForegroundColor);
    //.padding(@input-padding-v, @input-padding-h, @input-padding-v, @input-padding-h);
    .border-radius(@default-radius, @default-radius, @default-radius, @default-radius);
  }

  &.readonly {
    .p-fileupload {
      display: none;
    }
  }

  .p-fileupload {
    //.border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    //.border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    //.border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    //.border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    .p-fileupload-buttonbar, .p-fileupload-content {
      padding: 0.75rem;
      background-color: transparent;
    }
    /* button */
    .p-button {
      .background-color(@ButtonBackground);
      .color(@ButtonColor);
      .padding(@ButtonPaddingTop, @ButtonPaddingRight, @ButtonPaddingBottom, @ButtonPaddingLeft);
      //.border-width(@input-border-width, @input-border-width, @input-border-width, @input-border-width);
      //.border-style(solid, solid, solid, solid);
      //.border-color(@ButtonBorderColor, @ButtonBorderColor, @ButtonBorderColor, @ButtonBorderColor);
      border: none;
      .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
      .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);

      &:hover {
        .background-color(@ButtonHoverBackground);
      }

      .p-button-label {
        .line-height-double(@font-med);
      }
    }
  }

  .drop-zone {
    i {
      .color-important(@font-color);
      .border-color(@font-color)!important;
      opacity: 0.5;
    }
  }

  .files-list {
    .single-file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .color(@font-color);
      .padding(@input-padding-v, @input-padding-h, @input-padding-v, @input-padding-h);
      .background-color(@BackgroundColor);
      .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
      .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
      .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
      .border-radius(@default-radius, @default-radius, @default-radius, @default-radius);
      margin-top: 0.5rem;

      > div {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }

      .name {
        margin-left: 0.5rem;
        font-family: @main-font;
        font-style: normal;
      }

      .remove {
        cursor: pointer;
        opacity: @RemoveIconOpacity;
      }
    }
  }

  .progressbar-container {
      margin-top: 0.5rem;
  }
}

