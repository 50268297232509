@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

.repeatercontrol-status-mixin() {

  div.header {
    border-top: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    padding: 1rem 1rem;
    background-color: @color-default-contrast;
  }

}

