@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/borders.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

zapp-searchmodal {
  .zapp-modal {
    width: 960px;
  }
  zappicon > i {
    padding: 0 !important;
  }
  .p-tabview-panels {
    height: 400px;
    overflow-y: auto;
  }
  .search-result-row {
    cursor: pointer;
  }
  .search-indexes-checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .search-box {
    margin-bottom: 1rem;
  }
  .nothing-to-show-label {
    display: block;
    text-align: center;
  }
  .clickable-icon {
    cursor: pointer;
  }
}
