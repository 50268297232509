@import '../../@framework/components/Grid/Grid.component.less';

.grid-global-variables() {
                  .global-variables();
                  @BorderBottomColor: @border-color;
  @BorderLeftColor: @border-color;
  @BorderRightColor: @border-color;
  @BorderTopColor: @border-color;
  @FooterColor: @footer-color;
  @HeaderBackgroundColor: @header-bg;
  @HeaderColor: @header-color;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @row-hover-bg;
  @RowHoverColor: @row-hover-color;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;

                }

                .zapp-grid  {
                  .grid-global-variables();
                  
                  
                  
                  .grid-status-mixin();
}

.grid-status-default {
  .global-variables();
  @FooterBackgroundColor: @color-default;
  @FooterColor: @color-default-contrast;
  @HeaderBackgroundColor: @color-default;
  @HeaderColor: @color-default-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-default-hover;
  @RowHoverColor: @color-default-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}

.grid-status-primary {
  .global-variables();
  @FooterBackgroundColor: @color-primary;
  @FooterColor: @color-primary-contrast;
  @HeaderBackgroundColor: @color-primary;
  @HeaderColor: @color-primary-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-primary-hover;
  @RowHoverColor: @color-primary-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}

.grid-status-info {
  .global-variables();
  @FooterBackgroundColor: @color-info;
  @FooterColor: @color-info-contrast;
  @HeaderBackgroundColor: @color-info;
  @HeaderColor: @color-info-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-info-hover;
  @RowHoverColor: @color-info-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}

.grid-status-success {
  .global-variables();
  @FooterBackgroundColor: @color-success;
  @FooterColor: @color-success-contrast;
  @HeaderBackgroundColor: @color-success;
  @HeaderColor: @color-success-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-success-hover;
  @RowHoverColor: @color-success-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}

.grid-status-warning {
  .global-variables();
  @FooterBackgroundColor: @color-warning;
  @FooterColor: @color-warning-contrast;
  @HeaderBackgroundColor: @color-warning;
  @HeaderColor: @color-warning-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-warning-hover;
  @RowHoverColor: @color-warning-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}

.grid-status-danger {
  .global-variables();
  @FooterBackgroundColor: @color-danger;
  @FooterColor: @color-danger-contrast;
  @HeaderBackgroundColor: @color-danger;
  @HeaderColor: @color-danger-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-danger-hover;
  @RowHoverColor: @color-danger-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .grid-status-mixin();
}







.additemlabel-global-variables() {
                  .global-variables();
                  @ForegroundColor: @header-bg;
  @IconOpacity: 0.85;

                }

                .zapp-additemlabel  {
                  .additemlabel-global-variables();
                  
                  
                  
                  .additemlabel-status-mixin();
}

.additemlabel-status-default {
  .global-variables();
  @ForegroundColor: @color-default;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}

.additemlabel-status-primary {
  .global-variables();
  @ForegroundColor: @color-primary;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}

.additemlabel-status-info {
  .global-variables();
  @ForegroundColor: @color-info;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}

.additemlabel-status-success {
  .global-variables();
  @ForegroundColor: @color-success;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}

.additemlabel-status-warning {
  .global-variables();
  @ForegroundColor: @color-warning;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}

.additemlabel-status-danger {
  .global-variables();
  @ForegroundColor: @color-danger;
  @IconOpacity: 0.85;



  .additemlabel-status-mixin();
}







.removeitemlabel-global-variables() {
                  .global-variables();
                  @ForegroundColor: @color-danger;
  @IconOpacity: 0.5;

                }

                .zapp-removeitemlabel  {
                  .removeitemlabel-global-variables();
                  
                  
                  
                  .removeitemlabel-status-mixin();
}













.pager-global-variables() {
                  .global-variables();
                  @FontSize: @font-med;
  @FontDecoration: none;

                }

                .zapp-pager  {
                  .pager-global-variables();
                  
                  
                  
                  .pager-status-mixin();
}

.pager-status-default {
  .global-variables();
  @ActiveColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;
  @FontSize: @font-med;
  @ForegroundColor: @color-default;
  @HoverBackgroundColor: @color-default;
  @HoverColor: @color-default-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}

.pager-status-primary {
  .global-variables();
  @ActiveColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;
  @FontSize: @font-med;
  @ForegroundColor: @color-primary;
  @HoverBackgroundColor: @color-primary;
  @HoverColor: @color-primary-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}

.pager-status-info {
  .global-variables();
  @ActiveColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;
  @FontSize: @font-med;
  @ForegroundColor: @color-info;
  @HoverBackgroundColor: @color-info;
  @HoverColor: @color-info-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}

.pager-status-success {
  .global-variables();
  @ActiveColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;
  @FontSize: @font-med;
  @ForegroundColor: @color-success;
  @HoverBackgroundColor: @color-success;
  @HoverColor: @color-success-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}

.pager-status-warning {
  .global-variables();
  @ActiveColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;
  @FontSize: @font-med;
  @ForegroundColor: @color-warning;
  @HoverBackgroundColor: @color-warning;
  @HoverColor: @color-warning-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}

.pager-status-danger {
  .global-variables();
  @ActiveColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;
  @FontSize: @font-med;
  @ForegroundColor: @color-danger;
  @HoverBackgroundColor: @color-danger;
  @HoverColor: @color-danger-contrast;
  @ActiveBackgroundColor: transparent;
  @ActiveBorderColor: transparent;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @FontDecoration: none;
  @MarginRight: 10px;
  @PaddingBottom: 6px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @PaddingTop: 6px;



  .pager-status-mixin();
}







