@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

/* Views */

.views-button {
  label {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.views-overlay {
  zappIcon {
    i {
      padding: 0.5rem 0.25rem;
      border-radius: 4px;
    }
  }
  .views-overlay-current-view {
    display: flex;
    align-items: center;
    label, zappTextbox {
      flex: 0 1 100%;
      margin-right: 0.25rem;
    }
  }
  .search-box {
    padding-bottom: 0.5rem;
  }
  td {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .view-label {
    margin-left: 0.5rem;
  }
  .view-label-bold {
    font-weight: bold;
  }
  .views-grid-row {
    .label-cell {
      cursor: pointer;
    }
    .save-cell {
      visibility: hidden;
      i:hover {
        background-color: #ccc;
      }
    }
    .delete-cell {
      visibility: hidden;
      i:hover {
        background-color: #ccc;
      }
    }
    .set-default-cell {
      visibility: hidden;
      i:hover {
        background-color: #ccc;
      }
    }
  }
  .views-grid-row:hover {
    .save-cell {
      visibility: visible;
    }
    .delete-cell {
      visibility: visible;
    }
    .set-default-cell {
      visibility: visible;
    }
  }
  .views-grid-row-current {
    .label-cell {
      font-weight: bold;
    }
  }
  .create-new-view {
    cursor: pointer;
    font-weight: normal;
  }
}

/* End Views */

:host ::ng-deep {
  .zapp-checkbox {
    margin-bottom: 0 !important;
  }

  .p-dialog .p-dialog-content {
    padding: 1.5rem 1.5rem 2rem 1.5rem;
  }

  #FiltersModal {
    table {
      width: 100%;
      text-align: left;

      thead {
        tr {
          background-color: #f0f0f7;

          th {
            padding: 0.75rem;
          }
        }
      }

      tfoot {
        tr {
          td {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }

  #ImportModal {
    .import-results-table {
      margin-top: 10px;
    }

    .btn-csv-download {
      margin-bottom: 4px;
      display: inline-block;
    }
  }

  .subheader-row {
    &.level-2 > td {
      padding-left: 3rem;
    }

    &.level-3 > td {
      padding-left: 6rem;
    }

    td.compact {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    button {
      vertical-align: middle;
    }
  }

  tr.hidden {
    display: none !important;
  }

  .p-datatable-resizable .p-datatable-tbody > tr > td {
    white-space: unset;
  }

  .columns-visibility-wrapper {
    display: inline-block;

    .p-multiselect.p-inputwrapper {
      visibility: hidden;
      width: 0;
      min-width: 0;
      height: 0;
      min-height: 0;


      > * {
        display: none;
      }
    }
  }

  .p-column-title {
    font-weight: bold;
  }
  // stack layout
  .stack-layout {
    @media screen and (max-width: 960px) {
      .p-datatable-tbody > tr > td {
        display: flex;
        width: 100% !important;
        align-items: center;
        justify-content: space-between;
        min-width: unset !important; // override fixed column width
        max-width: unset !important; // override fixed column width

        p-cellEditor.progress-bar {
          min-width: 200px;
        }
      }
    }

    @media screen and (max-width: 960px) {
      .p-datatable-tbody > tr > td > .p-column-title {
        display: block;
      }
    }

    @media screen and (max-width: 960px) {
      .p-datatable-thead > tr > th, #pn_id_61-table > .p-datatable-tfoot > tr > td {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .list-header {
      flex-direction: column;
    }

    .actions {
      width: 100%;
    }

    .buttons-container {
      width: 100%;

      .settings {
        width: 100%;
      }

      .buttons {
        width: 100%;
        justify-content: flex-end;

        > zapp-button:last-child {
          button {
            margin-right: 0;
          }
        }
      }
    }

    .filter {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }

  p-columnfilterformelement {
    display: flex;
    flex-direction: column;

    .filter-container {
      align-items: center;

      &.has-quick-filter + .description {
        min-height: 18px !important;
      }
    }

    .description {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal;
      font-style: italic;
      opacity: 0.5;
    }
  }
}

.list-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -0.5rem;

  > * {
    margin-top: 0.5rem;
  }
}

.buttons-container {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0!important;

  > * {
    margin-top: 0.5rem;
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -0.5rem !important;

  > * {
    margin-top: 0.5rem;
  }
}

.list-status-mixin() {

  .global-search-container {
    width: 20rem;
    margin-right: 0.5rem;

    &.has-quick-filter {
      .filter-icon {
        .color(@color-warning);
      }
    }

    .filter-icon {
      cursor: pointer;
      z-index: 1;

      .p-badge {
        width: 13px;
        min-width: 13px;
        height: 13px;
        line-height: 13px;
      }
    }
  }

  .subheader-row {
    .caption {
      .color(@PagerDisabledColor);
    }

    .value {
      .color(@PagerActiveColor);
    }
  }

  .aggregations {
    list-style: none;
    padding-left: 0;
    margin: 0;

    .caption {
      .color(@PagerDisabledColor);
    }

    .value {
      margin-left: 0.5rem;
      .color(@PagerActiveColor);
    }
  }

  .p-datatable-footer {
    .color(@HeaderColor);
  }
  /* actions header */
  .p-datatable .p-datatable-header {
    .background-color(@RowBackground);
    .color(@RowColor);
    .border-color(@CellBorderColorTop, @CellBorderColorRight, @CellBorderColorBottom, @CellBorderColorLeft);
  }
  /* header */
  .p-datatable .p-datatable-thead > tr > th {
    .background-color(@HeaderBackgroundColor);
    .color(@HeaderColor);
    .border-color(@CellBorderColorTop, @CellBorderColorRight, @CellBorderColorBottom, @CellBorderColorLeft);
  }
  /* rows */
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even),
  .p-datatable .p-datatable-tbody > tr {
    .background-color(@RowBackground);
    .color(@RowColor);
  }

  .p-datatable:not(.p-datatable-checkbox-select) .p-datatable-tbody > tr.p-highlight {
    .background-color-important(@RowSelectedBackground);
    .color-important(@RowSelectedColor);

    a {
      .color-important(@RowSelectedColor);
    }
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    .background-color(@RowHoverBackground);
    .color(@RowHoverColor);
  }
  /* alternate rows */
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
    .background-color(@RowAlternateBackground);
    .color(@RowAlternateColor);
  }
  /* pager */
  .p-paginator {
    .background-color(@FooterBackgroundColor);
    .color(@PagerColor);
    .border-color(@CellBorderColorTop, @CellBorderColorRight, @CellBorderColorBottom, @CellBorderColorLeft);
    .border-radius(@PagerBorderRadius, @PagerBorderRadius, @PagerBorderRadius, @PagerBorderRadius);
    .border-width(@PagerBorderWidth, @PagerBorderWidth, @PagerBorderWidth, @PagerBorderWidth);

    span {
      .color(@PagerColor);
    }

    .p-dropdown {
      .background-color-important(@FooterBackgroundColor);
      .border-color(@PagerColor, @PagerColor, @PagerColor, @PagerColor);
    }

    .p-paginator-pages .p-paginator-page.p-highlight {
      .background-color(@PagerActiveBackgroundColor);
      .color(@PagerActiveColor);
    }

    .p-paginator-pages .p-paginator-page:hover {
      .background-color(@PagerHoverBackgroundColor);
      .color(@PagerHoverColor);
    }
  }
  /* grouping */
  .p-datatable .p-datatable-tfoot > tr > td,
  .p-datatable .p-datatable-footer {
    .background-color-important(@FooterBackgroundColor);
  }
  /* borders */
  .p-datatable .p-datatable-tbody > tr > td {
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@CellBorderColorTop, @CellBorderColorRight, @CellBorderColorBottom, @CellBorderColorLeft);
  }

  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    .background-color-important(@RowHoverBackground);
    .color(@RowHoverColor);
  }

  p-datatable-table {
    tr {
      td {
        .min-height(@CellHeight);
      }
    }
  }

  .header-cell {
    &:hover {
      .quick-fiilter-clear {
        opacity: 1;
      }
    }

    .quick-fiilter-clear {
      margin-left: 0.25rem;
      opacity: 0;
    }
  }

  .filter-icon {
    .color(@color-warning);
  }
}

.p-contextmenu {
  &.auto-expand {
    width: unset;
  }

  .p-menuitem.aggregations {
    .p-menuitem-text {
      display: flex;
      white-space: nowrap;

      .p-menuitem-icon {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
  }
}

.dataTables_info {
  padding-top: 0 !important;
}

.select-actions {
  display: flex;
  flex-wrap: wrap;
  margin-right: 1rem;

  button {
    display: none;
  }
}

.available-columns {
  display: inline-block;
  vertical-align: middle;
  height: 250px;
  width: 200px;
  padding: 0px;
  overflow: auto;
}

.grouping-columns {
  display: inline-block;
  vertical-align: middle;
  height: 250px;
  width: 200px;
  padding: 0px;
  overflow: auto;
}

.groupingButtons {
  margin-left: 5px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}

.modal-list {
  max-width: 600px !important;
}

/****************** [BEGIN] Export Dialog ******************/

.export-pop-up * {
  text-shadow: none !important;
}

.export-pop-up input[type='text'],
.export-pop-up select {
  width: 100% !important;
}


.export-pop-up input[type='checkbox'] {
  margin: 0px !important;
}

#exportDisplayColumns {
  margin-top: 10px;
}

#disCols {
  cursor: pointer;
}

.jscolor {
  height: 15px;
  border: none;
  border-radius: 5px;
  width: 15px;
  outline: none !important;
  float: left;
  margin-right: 5px;
  cursor: pointer;
}

.colorpicker-holder {
  opacity: 0;
  height: 0 !important;
  overflow: hidden;
}

.colorpicker-holder .colorpicker {
  position: relative;
}

.colorpicker-holder.colorpicker-visible {
  opacity: 1;
  height: 173px;
}

.colorpicker-holder.colorpicker-visible .colorpicker {
  position: absolute !important;
}

/****************** Export Dialog [END] ******************/

/****************** [BEGIN] Export V2 Dialog ******************/
/* NOTE: Some styles are copied here from the original export version.
         This way it can be removed completetly without breaking anything :)
*/

.export-pop-up input[type='text'],
.export-pop-up select {
  width: 100% !important;
}

.export-pop-up input[type='checkbox'] {
  margin: 0px !important;
}

.export-pop-up .export-options {
}


.export-pop-up .export-colors {
  width: 50%;
  float: right;
}

.jscolor {
  height: 15px;
  border: #ddd 1px solid;
  border-radius: 5px;
  width: 15px;
  outline: none !important;
}

/****************** Export Dialog V2 [END] ******************/

/****************** [BEGIN] Grouping Dialog ******************/
.datalist-grouping {
  justify-content: center;

  .group-left, .group-right {
    width: 280px;
  }
}

.datalist-grouping .modal-title {
  text-align: center;
}

.datalist-grouping .control-column {
  width: 50px;
  vertical-align: middle;
}

.datalist-grouping .control-column .btn-group {
  margin: 5px 0;
}

.datalist-grouping select {
  font-size: 12px;
  height: 200px;
  width: 215px;
  padding: 5px;
  word-wrap: break-word;
}
/****************** Grouping Dialog [END] ******************/

/******************** Views Pop Up [END] *********************************/

table.views-table {
  width: 100%;
}

table.views-table th:nth-child(1) {
  width: 60%;
}

table.views-table th:nth-child(2) {
  width: 15%;
}

table.views-table thead tr {
  height: 50px;
  font-size: 15px;
}

table.views-table td:not(:first-child),
table.views-table th:not(:first-child) {
  text-align: center;
}

table.views-table a {
  cursor: pointer;
}

table.views-table td .btn {
  width: 100%;
}

/******************** Views Pop Up [END] *********************************/

/******************** [START] Information pop-up *********************************/

.dt-button-info {
  z-index: 500;
}

/******************** Information pop-up [END] *********************************/

/******************** [START] Filters pop-up *********************************/

.data-list-filters-modal tr > td,
.data-list-filters-modal tr > th {
  border: none !important;
}

.data-list-filters-modal tbody > tr:hover {
  background-color: rgba(255,255,255, 0.2);
}



/******************** Filters pop-up [END] *********************************/



