@import '../../@framework/components/ImageBox/imagebox.component.less';
.imagebox-global-variables() {
                  .global-variables();
                  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ButtonBackground: @color-primary;
  @ButtonColor: @color-primary-contrast;
  @ButtonPaddingBottom: 0.25rem;
  @ButtonPaddingLeft: 1rem;
  @ButtonPaddingRight: 1rem;
  @ButtonPaddingTop: 0.25rem;

                }

                .zapp-imagebox  {
                  .imagebox-global-variables();
                  
                  
                  
                  .imagebox-status-mixin();
}













