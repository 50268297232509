@import '../../@framework/components/RepeaterControl/RepeaterControl.component.less';

.repeatercontrol-global-variables() {
                  .global-variables();
                
                }

                .zapp-repeatercontrol  {
                  .repeatercontrol-global-variables();
                  
                  
                  
                  .repeatercontrol-status-mixin();
}













